define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/initializers/initialize-discourse-prometheus-alert-receiver", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-prometheus-alert-receiver",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.9", api => {
        api.decorateWidget("post-contents:after-cooked", dec => {
          if (dec.attrs.post_number === 1) {
            const postModel = dec.getModel();
            if (postModel?.topic?.alert_data) {
              return dec.attach("alert-receiver-data", {
                topic: postModel.topic
              });
            }
          }
        });
      });
    }
  };
});