define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/timestamp", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/local-dates", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _localDates, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class="alert-receiver-date discourse-local-date"
    data-format={{if @hideDate "HH:mm" "YYYY-MM-DD HH:mm"}}
    data-date={{@date}}
    data-time={{@time}}
    data-timezone="UTC"
    data-displayed-timezone="UTC"
    {{did-insert this.applyLocalDates}}
  >{{@date}}T{{@time}}</span>
  */
  {
    "id": "/fgqAZxi",
    "block": "[[[11,1],[24,0,\"alert-receiver-date discourse-local-date\"],[16,\"data-format\",[52,[30,1],\"HH:mm\",\"YYYY-MM-DD HH:mm\"]],[16,\"data-date\",[30,2]],[16,\"data-time\",[30,3]],[24,\"data-timezone\",\"UTC\"],[24,\"data-displayed-timezone\",\"UTC\"],[4,[38,1],[[30,0,[\"applyLocalDates\"]]],null],[12],[1,[30,2]],[1,\"T\"],[1,[30,3]],[13]],[\"@hideDate\",\"@date\",\"@time\"],false,[\"if\",\"did-insert\"]]",
    "moduleName": "discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/timestamp.hbs",
    "isStrictMode": false
  });
  class AlertReceiverDate extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    applyLocalDates(element) {
      (0, _localDates.applyLocalDates)([element], this.siteSettings);
    }
    static #_2 = (() => dt7948.n(this.prototype, "applyLocalDates", [_object.action]))();
  }
  _exports.default = AlertReceiverDate;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AlertReceiverDate);
});