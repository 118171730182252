define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/collapse-toggle", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{on "click" @toggleCollapsed}}
    class={{concat-class
      "alert-receiver-collapse-toggle"
      (if @collapsed "collapsed")
    }}
    role="button"
  >
    <div class="collapse-icon">
      <a>{{d-icon (if @collapsed "caret-right" "caret-down")}}</a>
    </div>
    <div class="heading">
      {{@heading}}
      ({{@count}})
    </div>
    <AlertReceiver::ExternalLink @link={{@headingLink}} />
  </div>
  */
  {
    "id": "OEiqITm2",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"alert-receiver-collapse-toggle\",[52,[30,1],\"collapsed\"]],null]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,2]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"collapse-icon\"],[12],[1,\"\\n    \"],[10,3],[12],[1,[28,[35,3],[[52,[30,1],\"caret-right\",\"caret-down\"]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n    (\"],[1,[30,4]],[1,\")\\n  \"],[13],[1,\"\\n  \"],[8,[39,4],null,[[\"@link\"],[[30,5]]],null],[1,\"\\n\"],[13]],[\"@collapsed\",\"@toggleCollapsed\",\"@heading\",\"@count\",\"@headingLink\"],false,[\"concat-class\",\"if\",\"on\",\"d-icon\",\"alert-receiver/external-link\"]]",
    "moduleName": "discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/collapse-toggle.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});