define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/widgets/alert-receiver", ["discourse/widgets/render-glimmer", "@ember/template-factory"], function (_renderGlimmer, _templateFactory) {
  "use strict";

  (0, _renderGlimmer.registerWidgetShim)("alert-receiver-data", "div.prometheus-alert-receiver", (0, _templateFactory.createTemplateFactory)(
  /*
    <AlertReceiver::Data @topic={{@data.topic}} />
  */
  {
    "id": "Um5tIzHE",
    "block": "[[[8,[39,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null]],[\"@data\"],false,[\"alert-receiver/data\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-prometheus-alert-receiver/discourse/widgets/alert-receiver.js",
    "isStrictMode": false
  }));
});